import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PaymentsLinearChart from "./payments";
import LocationsSalesChart from "./locations-sales";
import StatisticsBox from "./statistics-box";
import totalSalesIcon from "src/assets/svg-icons/total-sales.svg";
import paidIcon from "src/assets/svg-icons/paid.svg";
import freeIcon from "src/assets/svg-icons/free.svg";
import SalesTable from "./sales-table";
import { httpsCallable } from "firebase/functions";
import { functions } from "src/firebase";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import DateRangeInput from "src/shared/components/date-range";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ISalesSummaryReport } from "src/types/reports";
import { formatNumber } from "src/utils";
import { useGetLocationsList } from "src/api/locations";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let requestCounter = 0;

const ReportsSalesSummaryPage = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("day").format("yyyy-MM-DD"),
  });
  const [tempDateRange, setTempDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("day").format("yyyy-MM-DD"),
  });
  const [data, setData] = useState<ISalesSummaryReport>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [tempSelectedLocations, setTempSelectedLocations] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string>("all");
  const [focusedInput, setFocusedInput] = useState(null);

  //callable functions
  const fetchSalesSummaryReport = httpsCallable(functions, "getSalesSummary");

  // APIS
  const { data: locationsData } = useGetLocationsList();

  const handleChangeLocations = (value: any[], reason: string) => {
    setTempSelectedLocations(value);

    if (reason === "clear") {
      setSelectedLocations([]); // Clear the input value
    }
  };

  const handleChangeServiceType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedService(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        requestCounter++;
        const filters = {
          ...(selectedLocations.length > 0 && {
            locations: selectedLocations.map((loc) => loc.nameEn),
          }),
          ...(selectedService !== "all" && {
            services: [selectedService],
          }),
          ...(dateRange.startDate && {
            startDate: new Date(dateRange.startDate).getTime(),
          }),
          ...(dateRange.endDate && {
            endDate: new Date(dateRange.endDate).getTime(),
          }),
        };

        const result: any = await fetchSalesSummaryReport(filters);
        setData(result.data.data as ISalesSummaryReport);
      } catch (error: any) {
        const { message, details } = error;
        console.error("Error fetching sales summary report:", message, details);
        // Handle the error (e.g., show a notification or alert)
      } finally {
        requestCounter--; // Decrease the counter when a request finishes
        if (requestCounter === 0) {
          setIsLoading(false); // Only set isLoading to false when all requests are done
        }
      }
    };

    if (dateRange.startDate && dateRange.endDate) {
      fetchData();
    }
  }, [selectedLocations, dateRange, selectedService]);

  // Effect to save the date range when the date picker is closed
  useEffect(() => {
    if (focusedInput === null) {
      // Date picker is closed, now update the dateRange state
      setDateRange(tempDateRange);
    }
  }, [focusedInput, tempDateRange, dateRange]);

  const sortedLocations = useMemo(() => {
    return (
      locationsData?.locations.sort((a: any, b: any) =>
        a.nameEn?.localeCompare(b.nameEn)
      ) || []
    );
  }, [locationsData]);

  // console.log({
  //   data,
  //   locations,
  //   requestCounter,
  //   isLoading,
  //   dateRange,
  //   tempDateRange,
  //   fetchSalesSummaryReport,
  // });

  return (
    <Box p={{ xs: "20px 12px", md: "30px 20px", lg: "30px 30px" }}>
      <Typography fontWeight={600} fontSize={"22px"} mb={{ xs: 2, md: 4 }}>
        Sales Summary
      </Typography>

      {/* filters */}
      <Stack direction={"row"} gap={"10px"} flexWrap={"wrap"} mb={3}>
        {/* locations */}
        <Autocomplete
          sx={{ width: { xs: "100%", sm: "20%" } }}
          size="small"
          limitTags={1}
          multiple
          id="tags-outlined"
          options={sortedLocations}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.nameEn}
          renderOption={(props, option, { selected }) => (
            <li key={`${props.id} ${option.id}`} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Typography>{option.nameEn}</Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Location" placeholder="" />
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option: any, index: number) => (
              <Chip
                size="small"
                label={option.nameEn}
                {...getTagProps({ index })}
                key={option.id}
                onDelete={undefined} // Disable the "x" delete icon
              />
            ))
          }
          onChange={(event, value, reason) => {
            handleChangeLocations(value, reason);
          }}
          onClose={() => {
            setSelectedLocations(tempSelectedLocations);
          }}
        />
        {/* service */}
        <FormControl
          fullWidth
          size="small"
          sx={{ width: { xs: "100%", sm: "15%" } }}
        >
          <InputLabel id="demo-simple-select-label">Service</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedService}
            label="Service"
            onChange={handleChangeServiceType}
            fullWidth
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"valet"}>Valet</MenuItem>
            <MenuItem value={"selfParking"}>Self Parking</MenuItem>
          </Select>
        </FormControl>
        {/* date range */}
        <Box sx={{ minWidth: 183 }}>
          <DateRangeInput
            startDate={
              tempDateRange.startDate ? moment(tempDateRange.startDate) : null
            } // moment.Moment | null
            startDateId={`your_unique_start_date_id`}
            endDate={
              tempDateRange.endDate ? moment(tempDateRange.endDate) : null
            } // moment.Moment | null
            endDateId={`your_unique_end_date_id`}
            onDatesChange={(arg: {
              startDate: moment.Moment | null;
              endDate: moment.Moment | null;
            }) => {
              setTempDateRange({
                startDate: arg.startDate?.format("YYYY-MM-DD") as string,
                endDate: arg.endDate?.format("YYYY-MM-DD") as string,
              });
              if (arg.startDate === null && arg.endDate === null) {
                setDateRange({
                  startDate: "",
                  endDate: "",
                });
              }
            }}
            onFocusChange={(focused: any) => setFocusedInput(focused)}
          />
        </Box>
      </Stack>

      {!data || isLoading ? (
        <Box display={"flex"} justifyContent={"center"} pt={30}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <PaymentsLinearChart data={data?.salesByDay} />
          <Grid container mt={0} spacing={"20px"} mb={"20px"}>
            <Grid item xs={12} lg={8}>
              <LocationsSalesChart data={data.salesByLocation} />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              display={"flex"}
              direction={"column"}
              gap={"10px"}
            >
              <StatisticsBox
                label="Total Sales"
                value={`SAR ${formatNumber(data.overallSummary.totalSales)}`}
                icon={totalSalesIcon}
              />
              <StatisticsBox
                label="Paid Tickets"
                value={`${formatNumber(data.overallSummary.ticketPaidCount)}`}
                icon={paidIcon}
              />
              <StatisticsBox
                label="Free Tickets"
                value={`${formatNumber(data.overallSummary.freeTicketCount)}`}
                icon={freeIcon}
              />
            </Grid>
          </Grid>
          <SalesTable
            dateRange={dateRange}
            selectedLocations={selectedLocations}
            selectedService={selectedService}
          />
        </>
      )}
    </Box>
  );
};

export default ReportsSalesSummaryPage;
