import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import GatesInfo from "./gates-info";
import { FormInputs, schema } from "./constants";
import { useEffect, useState } from "react";
import LocationsMapLocation from "./google-map-location";
import AddEditServicePopup, {
  ServiceFormInputs,
} from "./add-edit-service-popup";
import ServicesTable from "./services-table";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useNavigate, useParams } from "react-router-dom";
import { FormModeTypes } from "src/types/generic";
import { LoadingButton } from "@mui/lab";
import {
  useAddLocationMutation,
  useGetLocationById,
  useUpdateLocationMutation,
} from "src/api/locations";
import { useGetCitiesList } from "src/api/general";
import AddIcon from "@mui/icons-material/Add";
import ActivateCitiesPopup from "./activate-cities-popup";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const AddEditLocationPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mode: FormModeTypes = !id ? "add" : "edit";
  const [clients, setClients] = useState<any[]>([]);
  const [services, setServices] = useState<ServiceFormInputs[]>([]);
  const [open, setOpen] = useState(false);
  const [openCitiesPopup, setOpenCitiesPopup] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [clickedCoordinates, setClickedCoordinates] = useState<{
    lat: number;
    lng: number;
  }>();

  const breadcrumbs = [
    <Link
      color={"#A1A5B7"}
      component={RouterLink}
      underline="hover"
      to="/locations/active"
    >
      Locations
    </Link>,

    <Typography key="3" color="#A1A5B7">
      {mode === "add" ? "Add Location" : "Update Location"}
    </Typography>,
  ];

  const methods = useForm<FormInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      gates: [
        {
          name: "",
        },
      ],
      clientId: "test", // TODO
    },
  });
  const { handleSubmit, formState, reset, control, watch } = methods;

  // APIS
  const { mutate, isPending, status, error } = useAddLocationMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateLocationMutation();
  const { data } = useGetLocationById({
    id: id ?? "",
    enabled: !!id,
  });
  const { data: citiesData, refetch: refetchCities } = useGetCitiesList({
    type: "active",
  });

  const isNotEqualSpaces =
    +watch("totalParkingSpaces") !==
      +watch("valetParkingSpaces") + +watch("selfParkingSpaces") &&
    +watch("totalParkingSpaces") >= 0 &&
    +watch("valetParkingSpaces") >= 0 &&
    +watch("selfParkingSpaces") >= 0;

  console.log({ formState }, formState.errors);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (services.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Add services please",
        },
      ]);
      return;
    }
    if (!clickedCoordinates?.lat) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Select location on map please",
        },
      ]);
      return;
    }
    if (isNotEqualSpaces) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            "Valet parking spaces + Self-parking spaces should equal The total parking spaces input.",
        },
      ]);
      return;
    }
    console.log({ data });
    const dataToSend = {
      nameEn: data.nameEn,
      nameAr: data.nameAr,
      locationAddress: data.locationAddress,
      locationContactNum: data.phoneNumber,
      clientId: "test", //TODO:data.clientId
      clientName: "test", //TODO:client.name
      locationGates: data.gates.map((gate) => gate.name),
      locationLatitude: clickedCoordinates?.lat,
      locationLongitude: clickedCoordinates?.lng,
      cityId: data.cityId,
      cityName:
        citiesData?.data.find((city) => city.id === data.cityId)?.nameEn || "",
      totalSpaces: +data.totalParkingSpaces,
      valetSpaces: +data.valetParkingSpaces,
      selfSpaces: +data.selfParkingSpaces,
      services: services.map((service) => {
        return {
          type: service.type,
          name: service.name,
          paymentConfig: service.payment,
          ...(Number(service.gracePeriod) >= 0 && {
            gracePeriod: Number(service.gracePeriod),
          }),
          pricingType: service.pricingType,
          ...(Number(service.startingHours) >= 0 && {
            customStartHrs: Number(service.startingHours),
          }),
          ...(Number(service.priceOfExceedingHours) >= 0 && {
            customExceedingPrice: Number(service.priceOfExceedingHours),
          }),
          customExceedingPriceType: service.exceedingPricingType,
          ...(Number(service.ticketViolationPrice) >= 0 && {
            lossViolationPrice: Number(service.ticketViolationPrice),
          }),
          ...(Number(service.price) >= 0 &&
            service.pricingType === "fixed" && {
              fixedPrice: Number(service.price),
            }),
          ...(Number(service.price) >= 0 &&
            service.pricingType === "hourly" && {
              hourlyPrice: Number(service.price),
            }),
          ...(Number(service.price) >= 0 &&
            service.pricingType === "custom" && {
              customPrice: Number(service.price),
            }),
          // customModel: service.pricing,
        };
      }),
    };
    console.log({ dataToSend });
    if (mode === "add") {
      console.log({ dataToSend });
      mutate(dataToSend);
    } else {
      mutateUpdate({ id: id, ...dataToSend });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleOpenCitiesPopup = () => {
    setOpenCitiesPopup(true);
  };

  const onCloseCitiesPopup = () => {
    setOpenCitiesPopup(false);
  };

  // useEffect(() => {
  //   let unsubscribeClients: () => void;

  //   unsubscribeClients = onSnapshot(
  //     query(collection(db, "clients")),
  //     (QuerySnapshot) => {
  //       const fetchedClients: any[] = [];
  //       QuerySnapshot.forEach((doc) => {
  //         fetchedClients.push({ ...doc.data(), id: doc.id });
  //       });

  //       setClients(fetchedClients);
  //     }
  //   );

  //   return () => {
  //     if (unsubscribeClients) {
  //       unsubscribeClients();
  //     }
  //   };
  // }, []);

  //Fetch Locations data
  useEffect(() => {
    if (data) {
      reset({
        clientId: data.clientId || "test", //TODO
        cityId: data.cityId,
        nameAr: data.nameAr,
        nameEn: data.nameEn,
        locationAddress: data.locationAddress,
        phoneNumber: data.locationContactNum,
        totalParkingSpaces: data.totalSpaces,
        valetParkingSpaces: data.valetSpaces,
        selfParkingSpaces: data.selfSpaces,
        gates:
          data.locationGates?.length > 0
            ? data.locationGates.map((gate) => {
                return {
                  name: gate,
                };
              })
            : [
                {
                  name: "",
                },
              ],
      });
      setClickedCoordinates({
        lat: data.locationLatitude,
        lng: data.locationLongitude,
      });
      setServices(
        data.services.map((service) => {
          return {
            type: service.type,
            name: service.name,
            payment: service.paymentConfig,
            pricingType: service?.pricingType,
            price:
              service.pricingType === "fixed"
                ? service?.fixedPrice
                : service.pricingType === "custom"
                ? service.customPrice
                : service.pricingType === "hourly"
                ? service.hourlyPrice
                : undefined,
            gracePeriod: service.gracePeriod ? service.gracePeriod : undefined,
            startingHours: service?.customStartHrs
              ? service?.customStartHrs
              : undefined,
            priceOfExceedingHours: service?.customExceedingPrice
              ? service?.customExceedingPrice
              : undefined,
            pricingModal: "customByHours",
            exceedingPricingType:
              service?.customExceedingPriceType ?? "perHour",
            ticketViolationPrice: service?.lossViolationPrice
              ? service?.lossViolationPrice
              : undefined,
          };
        })
      );
    }
  }, [data]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The location has been added successfully!",
        },
      ]);
      navigate("/locations/active");
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The location has been updated successfully!",
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [statusUpdate]);

  console.log({ clients, services, clickedCoordinates, data });
  console.log("formState errrors:", formState.errors);

  return (
    <Box p={"30px"}>
      <Typography mb={1} fontSize={"22px"} fontWeight={600}>
        {mode === "add" ? "Add New Location" : "Update Location"}
      </Typography>

      {/* <RouterBreadcrumbs /> */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ color: "#5E6278", mb: 3 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container spacing={2} mb={3}>
            {/* <Grid item xs={12} md={4}>
              <FormControl
                fullWidth
                size="small"
                error={!!formState.errors.clientId}
              >
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Controller
                  name="clientId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Client"
                    >
                      {clients.map((client) => {
                        return (
                          <MenuItem key={client.id} value={client.id}>
                            {client.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {formState.errors.clientId?.message}
                </FormHelperText>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <Controller
                name="nameEn"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Location Name in English"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.nameEn}
                    helperText={formState.errors.nameEn?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="nameAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Location Name in Arabic"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.nameAr}
                    helperText={formState.errors.nameAr?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                fullWidth
                size="small"
                error={!!formState.errors.cityId}
              >
                <InputLabel id="demo-simple-select-label">
                  Select a City
                </InputLabel>
                <Controller
                  name="cityId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select a City"
                    >
                      {citiesData?.data.map((city) => {
                        return (
                          <MenuItem key={city.id} value={city.id}>
                            {city.nameEn}
                          </MenuItem>
                        );
                      })}
                      <Box p={"4px 8px"}>
                        <Button
                          onClick={handleOpenCitiesPopup}
                          startIcon={<AddIcon />}
                          variant="text"
                        >
                          Activate New City
                        </Button>
                      </Box>
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {formState.errors.cityId?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="locationAddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Complete Address"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.locationAddress}
                    helperText={formState.errors.locationAddress?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Contact Number"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.phoneNumber}
                    helperText={formState.errors.phoneNumber?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* google map location */}
          <Box mb={2}>
            <LocationsMapLocation
              setClickedCoordinates={setClickedCoordinates}
              clickedCoordinates={clickedCoordinates}
            />
          </Box>
          {/* gates */}
          <GatesInfo isNotEqualSpaces={isNotEqualSpaces} />
          {/* services */}
          <Box
            p={3}
            borderRadius={"10px"}
            border={"1.036px solid #5B5B5B"}
            mb={"33px"}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography fontSize={"20px"} fontWeight={600}>
                Services
              </Typography>
              <Button variant="contained" onClick={handleOpen}>
                Add New Service
              </Button>
            </Stack>
            {services.length === 0 ? (
              <Box py={"80px"}>
                <Typography
                  fontSize={"18px"}
                  color={"#A3A3A3"}
                  fontWeight={500}
                  align="center"
                >
                  No Services Added Yet
                </Typography>
              </Box>
            ) : (
              <Box mt={"40px"}>
                <ServicesTable services={services} setServices={setServices} />
              </Box>
            )}
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Stack mt={3} spacing={2} direction={"row"}>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ minWidth: { xs: "100px", md: "208px" } }}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={isPending || isPendingUpdate}
                sx={{ minWidth: { xs: "100px", md: "208px" } }}
              >
                {mode === "add" ? "Add Location" : "Update Location"}
              </LoadingButton>
            </Stack>
          </Box>
        </FormProvider>
      </form>
      {open && (
        <AddEditServicePopup
          open={open}
          onClose={onClose}
          setServices={setServices}
          services={services}
        />
      )}
      {openCitiesPopup && (
        <ActivateCitiesPopup
          open={openCitiesPopup}
          onClose={onCloseCitiesPopup}
          refetchCities={refetchCities}
        />
      )}
    </Box>
  );
};

export default AddEditLocationPage;
