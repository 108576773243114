import * as yup from "yup";

// Regular expressions
// const englishRegex = /^[A-Za-z\s]+$/; // Matches only English letters and spaces
// const arabicRegex = /^[\u0600-\u06FF\s]+$/; // Matches only Arabic letters and spaces

const englishRegex = /^[A-Za-z0-9\s]+$/; // Matches English letters, numbers, and spaces
const arabicRegex = /^[\u0600-\u06FF0-9\s]+$/; // Matches Arabic letters, numbers, and spaces

export const schema = yup.object().shape({
  //basic info
  clientId: yup.string().required("Required"),
  cityId: yup.string().required("Required"),
  nameEn: yup
    .string()
    .required("Required")
    .matches(englishRegex, "Name must contain only English letters"),
  nameAr: yup
    .string()
    .required("Required")
    .matches(arabicRegex, "Name must contain only Arabic letters"),
  locationAddress: yup.string().required("Required"),
  phoneNumber: yup.string().required("Required"),
  //gates
  gates: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Required"),
      })
    )
    .required("Choose related payments is required"),
  totalParkingSpaces: yup
    .number()
    .typeError("Required")
    // .positive("Must be a positive number")
    .min(0, "Must be a positive number or zero")
    .required("Required"),
  valetParkingSpaces: yup
    .number()
    .typeError("Required")
    .min(0, "Must be a positive number or zero")
    .required("Required"),
  selfParkingSpaces: yup
    .number()
    .typeError("Required")
    .min(0, "Must be a positive number or zero")
    .required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}
